import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/summarize',
    name: 'summarize',
    component: () => import('../views/summarize/index.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/summarize/video.vue')
  },
  {
    path: '/videolist',
    name: 'videolist',
    component: () => import('../views/summarize/videolist.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/summarize/news.vue')
  },
  {
    path: '/newslist',
    name: 'newslist',
    component: () => import('../views/summarize/newslist.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
